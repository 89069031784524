import React, { lazy, Suspense } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles, Grid, Container, useMediaQuery } from '@material-ui/core';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { CtaSection } from '../components/General/ContactUs/CtaSection';
import { CustomerLogos } from '../components/CustomerLogos';
import { ContactUsBottomWave } from '../components/General/ContactUs/ContactUsBottomWave';
// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#5A7184',
		lineHeight: 1.8,
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	form: {
		zIndex: 1000,
		marginTop: '-6rem',
		boxShadow: 'none',
		[theme.breakpoints.down('lg')]: {
			marginTop: '-8rem',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '-6rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-4rem',
		},
	},
	cta: {
		[theme.breakpoints.up('lg')]: {
			marginTop: '-2rem',
			zIndex: 1000,
		},
	},
}));

const ContactUsPage = ({ data, location }) => {
	const contactUs = data.contactUs.edges[0].node;
	const classes = useStyles();

	const {
		hero,
		metaDescription,
		metaTitle,
		marketoId,
    pardotUrl,
		ctaBlurbs,
		_rawCtaHeader,
		ctaBody,
		privacyPolicy,
		customerLogos,
		customerLogosHeader,
		bottomWaveBackground,
		// bottomWaveImagesMobile,
		bottomWaveImagesDesktop,
	} = contactUs;
	const md = useMediaQuery('(max-width: 960px)');
	const lg = useMediaQuery('(max-width: 1280px)');

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<Container>
				<Grid
					container
					direction='row'
					justifyContent={md ? 'center' : 'space-between'}
					spacing={4}>
					<Grid item md={6} className={classes.form}>
						<Form
							formId={marketoId}
              pardotUrl={pardotUrl}
              contactForm={{
								header: '',
								body: null,
								privacyPolicy,
							}}
							privacy
							modal={false}
							contactUs={true}
							location={location}
						/>
					</Grid>
					<Grid item md={6} className={classes.cta}>
						<CtaSection
							header={_rawCtaHeader}
							body={ctaBody}
							blurbs={ctaBlurbs}
						/>
					</Grid>
				</Grid>
			</Container>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			<ContactUsBottomWave
				background={bottomWaveBackground}
				stats={bottomWaveImagesDesktop}
			/>
			<div style={{ marginTop: '-1rem' }}>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query ContactUsQuery {
		contactUs: allSanityContactUs {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					privacyPolicy
					marketoId
          pardotUrl
					ctaBody
					_rawCtaHeader
					ctaBlurbs {
						title
						icon
						accentColor {
							hexValue
						}
					}
					customerLogosHeader
					customerLogos {
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					bottomWaveBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					bottomWaveImagesDesktop {
						icon
						header
						body
					}
				}
			}
		}
	}
`;

export default ContactUsPage;
